:root {
    --media-tile-max-width: 235px;
    --player-height: 125px;
    --player-height-sm: 165px;
    --track-thumbnail-size: 70px;
    --track-thumbnail-size-xs: 60px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    1% {
        visibility: visible;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}