@font-face {
  font-family: 'TrilemmaUltralight';
  src: url('../public/assets/fonts/Trilemma/Trilemma-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'TrilemmaLight';
  src: url('../public/assets/fonts/Trilemma/Trilemma-Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TrilemmaRegular';
  src: url('../public/assets/fonts/Trilemma/Trilemma-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TrilemmaMedium';
  src: url('../public/assets/fonts/Trilemma/Trilemma-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TrilemmaSemibold';
  src: url('../public/assets/fonts/Trilemma/Trilemma-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TrilemmaBold';
  src: url('../public/assets/fonts/Trilemma/Trilemma-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'TrilemmaBlack';
  src: url('../public/assets/fonts/Trilemma/Trilemma-SemiBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'BelloRegular';
  src: url('../public/assets/fonts/BelloSmCp/BelloSmCp-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.MuiButton-root {
  border-radius: 25px !important;
  padding: 8px 15px !important;
}

.icon-trilemma-blue {
  color: transparent;
}
.icon-trilemma-blue * {
  stroke: #647cfa;
  fill: transparent;
}

.icon-trilemma {
  color: transparent;
}
.icon-trilemma * {
  fill: transparent;
}
.icon-trilemma-mail-lg * {
  stroke-opacity: 1;
  stroke-width: 0.5;
}
.icon-trilemma-illustration-lg * {
  stroke-opacity: 1;
  stroke-width: 4;
}
.icon-trilemma-gray * {
  stroke: #0c0c0cb3;
}
